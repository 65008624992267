import axios from "@/config/axios";

const initialState = () => {
  return {
    hospitals: null,
    hospitalAddStatus: false,
    addedHospitalData: null,
    allHospitals: [],
    singleHospital: null,
    hospitalValidationErrors: null,
    hospitalDeleteError: null,
    hospitalPhysicians: null,
    hospitalAccountManagers: null,
    hospitalPhysicianDeleteStatus: false,
    hospitalAccountManagerDeleteStatus: false,
    hospitalBranches: null,
    allhospitalAdmins: [],
    hospitalStatusUpdate: null,
    uplodConsentFormStatus: false,
    attachmentUploadURL: null,
    removeConsentFormStatus: false,
    updateConsentFormStatus: false,
    labAccountManagers: null,

  };
};

const state = initialState();

const getters = {
  getAllHospitals: (state) => state.hospitals,
  getHospitalAddStatus: (state) => state.hospitalAddStatus,
  getAddedHospitalData: (state) => state.addedHospitalData,
  getHospitalValidationErrors: (state) => state.hospitalValidationErrors,
  getSingleHospital: (state) => state.singleHospital,
  getHospitalDeleteError: (state) => state.hospitalDeleteError,
  getHospitalPhysicians: (state) => state.hospitalPhysicians,
  getHospitalPhysicianDeleteStatus: (state) =>
    state.hospitalPhysicianDeleteStatus,
  getHospitalAccountManagerDeleteStatus: (state) =>
    state.hospitalAccountManagerDeleteStatus,
  getHospitalAccountManagers: (state) => state.hospitalAccountManagers,
  getAllHospitalAdmins: (state) => state.allhospitalAdmins,
  getHospitalBranches: (state) => state.hospitalBranches,
  getHospitalStatusUpdate: (state) => state.hospitalStatusUpdate,
  getUplodConsentFormStatus: (state) => state.uplodConsentFormStatus,
  getFileUploadURLForAttachments: (state) => state.attachmentUploadURL,
  getRemoveConsentFormStatus: (state) => state.removeConsentFormStatus,
  getUpdateConsentFormStatus: (state) => state.updateConsentFormStatus,
  getLabAccountManagers: (state) => state.labAccountManagers
};

const mutations = {
  setAllHospitals(state, hospitals) {
    state.hospitals = hospitals;
  },
  setHospitalAddStatus(state, status) {
    state.hospitalAddStatus = status;
  },
  setAddedHospitalData(state, data) {
    state.addedHospitalData = data;
  },
  setHospitalValidationErrors(state, data) {
    state.hospitalValidationErrors = data;
  },
  setAllHospitalAdmins(state, admins) {
    state.allhospitalAdmins = admins;
  },

  setSingleHospital(state, singleHospital) {
    state.singleHospital = singleHospital;
  },
  setHospitalAddStatus(state, status) {
    state.hospitalAddStatus = status;
  },

  setHospitalDeleteError(state, data) {
    state.hospitalDeleteError = data;
  },

  setHospitalAccountManagers(state, data) {
    state.hospitalAccountManagers = data;
  },
  setLabAccountManagers(state, data) {
    state.labAccountManagers = data
  },
  setHospitalPhysicians(state, data) {
    state.hospitalPhysicians = data;
  },
  setHospitalPhysicianDeleteStatus(state, data) {
    state.hospitalPhysicianDeleteStatus = data;
  },
  setHospitalAccountManagerDeleteStatus(state, data) {
    state.hospitalAccountManagerDeleteStatus = data;
  },
  setHospitalBranches(state, data) {
    state.hospitalBranches = data;
  },
  setHospitalStatusUpdate(state, status) {
    state.hospitalStatusUpdate = status;
  },
  setUplodConsentFormStatus(state, data) {
    state.uplodConsentFormStatus = data
  },
  setFileUploadURLForAttachments(state, data) {
    state.attachmentUploadURL = data
  },
  setRemoveConsentFormStatus(state, data) {
    state.removeConsentFormStatus = data
  },
  setUpdateConsentFormStatus(state, data) {
    state.updateConsentFormStatus = data
  },
  reset: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  fetchAllHospitals: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setAllHospitals", null);

      const response = await axios.get("/hospitals", { params });

      commit("setAllHospitals", response.data);
    } catch (error) {
      commit("setAllHospitals", null);
      console.log(error);
    }
  },
  downloadHospitalData: async ({ commit }, params) => {
    try {
      const response = await axios.get("/hospitals/download-as-csv", {
        params,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "hospitals.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  },
  addHospital: async ({ commit, dispatch }, params) => {
    try {
      commit("setHospitalValidationErrors", {});
      commit("setHospitalAddStatus", false);
      const hospitalData = await axios.post("/hospitals", params);
      commit("setAddedHospitalData", hospitalData.data.data);
      commit("setHospitalAddStatus", true);
    } catch (error) {
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setHospitalValidationErrors", errorData);
      }
    }
  },
  fetchAllHospitalAdmins: async ({ commit, dispatch }, params) => {
    try {
      commit("errors/clear", null, { root: true });
      let param = {};
      param.search_string = params.search_string;
      param.limit = params.limit;
      param.page = params.page;
      param.get_all = params.get_all;
      param.order_by = params.order_by;
      param.order_type = params.order_type;
      commit("setAllHospitalAdmins", []);
      let response = await axios.get(
        "/hospitals/" + params.hospital_id + "/hospital-admins",
        { param }
      );
      commit("setAllHospitalAdmins", response.data);
    } catch (error) {
      commit("setAllHospitalAdmins", []);

      if (error.response) {
        await dispatch("errors/errorResponse", err.response, {
          root: true,
        });
      }
    }
  },

  fetchSingleHospital: async ({ commit, dispatch }, id) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setSingleHospital", null);

      let response = await axios.get("/hospitals/" + id);

      commit("setSingleHospital", response.data.data);
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },
  editHospital: async ({ commit, dispatch }, params) => {
    try {
      commit("setHospitalAddStatus", false);
      const hospitalData = await axios.put("/hospitals/" + params.id, params);
      commit("setAddedHospitalData", hospitalData.data);
      commit("setHospitalAddStatus", hospitalData.data);
    } catch (error) {
      commit("setHospitalAddStatus", false);
      if (error.response) {
        let errorData = await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
        commit("setHospitalValidationErrors", errorData);
      }
    }
  },

  clearValidationErrors: ({ commit }) => {
    commit("setHospitalValidationErrors", null);
  },

  fetchHospitalPhysicians: async ({ commit, dispatch }, params) => {
    let hospital_id = params.hospital_id;
    delete params.hospital_id;
    try {
      const response = await axios.get(
        "/hospitals/" + hospital_id + "/physicians",
        { params }
      );
      commit("setHospitalPhysicians", response.data);
    } catch (error) {
      commit("setHospitalPhysicians", null);
      if (error && error.data && error.data.message) {
        commit("setHospitalDeleteError", error.data.message);
      } else {
        commit("setHospitalDeleteError", "Error while deleting Hospital");
      }
    }
  },
  deleteHospitalPhysician: async ({ commit, dispatch }, params) => {
    try {
      await axios.delete(
        "/hospitals/" +
        params.hospital_id +
        "/physicians/" +
        params.physician_id
      );
      commit("setHospitalPhysicianDeleteStatus", true);
    } catch (error) {
      commit("setHospitalPhysicianDeleteStatus", false);
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        });
      }
    }
  },

  fetchHospitalAccountManagers: async ({ commit }, params) => {
    try {
      const response = await axios.get(
        "/hospitals/" + params.hospital_id + "/account-managers",
        { params }
      );
      commit("setHospitalAccountManagers", response.data);
    } catch (error) {
      commit("setHospitalAccountManagers", null);
      if (error && error.data && error.data.message) {
        commit("setHospitalDeleteError", error.data.message);
      } else {
        commit("setHospitalDeleteError", "Error while deleting Hospital");
      }
    }
  },
  fetchLabAccountManagers: async ({ commit }, params) => {
    try {
      const response = await axios.get(
        "/hospitals/" + params.hospital_id + "/lab-account-managers",
        { params }
      );
      commit("setLabAccountManagers", response.data);
    } catch (error) {
      commit("setLabAccountManagers", null);
      if (error && error.data && error.data.message) {
        commit("setHospitalDeleteError", error.data.message);
      } else {
        commit("setHospitalDeleteError", "Error while deleting Hospital");
      }
    }
  },
  deleteHospitalAccountManager: async ({ commit, dispatch }, params) => {
    try {
      await axios.delete(
        "/hospitals/" +
        params.hospital_id +
        "/account-managers/" +
        params.account_manager_id
      );
      commit("setHospitalAccountManagerDeleteStatus", true);
    } catch (error) {
      commit("setHospitalAccountManagerDeleteStatus", false);
      if (error && error.data) {
        commit("setHospitalDeleteError", error.data.message);
      } else {
        commit("setHospitalDeleteError", "Error while deleting Hospital");
      }
    }
  },

  fetchHospitalBranches: async ({ commit }, params) => {
    try {
      const response = await axios.get(
        "/hospitals/" + params.hospital + "/locations",
        { params }
      );
      commit("setHospitalBranches", response.data);
    } catch (error) {
      commit("setHospitalBranches", null);
    }
  },
  updateHospitalStatus: async ({ commit, dispatch }, { hospitalId, data }) => {
    try {
      commit("errors/clear", null, { root: true });
      commit("setHospitalStatusUpdate", false);
      await axios.patch(`hospitals/${hospitalId}/status`, data);
      commit("setHospitalStatusUpdate", true);
    } catch (error) {
      commit("setHospitalStatusUpdate", false);
      await dispatch("errors/errorResponse", error.response, { root: true });
    }
  },
  fetchDocumentUploadUrl: async ({ commit }, params) => {
    try {
      commit("setFileUploadURLForAttachments", null)
      commit("setUplodConsentFormStatus", false)
      const response = await axios.post(
        "/hospital/attachments",
        params
      )
      const data = response.data.data
      commit("setFileUploadURLForAttachments", data)
      commit("setUplodConsentFormStatus", true)
    } catch (error) {
      console.log(error)
    }
  },
  removeConsentForm: async ({ commit }, params) => {
    try {
      let hospital_id = params.hospital_id
      delete params.hospital_id
      commit("setRemoveConsentFormStatus", false)
      await axios.delete(
        `/hospital/${hospital_id}/remove-consentform`, {
        data: params
      }
      )
      commit("setRemoveConsentFormStatus", true)
    } catch (error) {
      console.log(error)
    }
  },
  updateConsentForm: async ({ commit }, params) => {
    try {
      let hospital_id = params.hospital_id
      delete params.hospital_id
      commit("setUpdateConsentFormStatus", false)
      await axios.patch(
        `/hospital/${hospital_id}/add-consentform`, params
      )
      commit("setUpdateConsentFormStatus", true)
    } catch (error) {
      console.log(error)
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
