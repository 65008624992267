import axios from "@/config/axios"

const initiateState = () => {
  return {
    allInsurances: [],
    addInsurance: false,
    insuranceById:null,
    updateInsurance:false,
    insuranceReport:false,
    removeInsurance:false
  }
}
const state = initiateState()
const getters = {
  getInsurances: (state) => state.allInsurances,
  getInsuranceCreationStatus: (state) => state.addInsurance,
  getInsuranceDetails: (state) => state.insuranceById,
  getUpdateInsuranceStatus:(state) => state.updateInsurance,
  getInsuranceReport:(state) => state.insuranceReport,
  getInsuranceRemoveStatus: (state) => state.removeInsurance
}
const mutations = {
  setInsurances(state, data) {
    state.allInsurances = data
  },
  setAddInsurance(state, data) {
    state.addInsurance = data
  },
  setInsuranceDetails(state, data){
    state.insuranceById = data
  },
  setUpdateInsurance(state, data){
    state.updateInsurance = data
  },
  setInsuranceReport(state, data){
    state.insuranceReport = data
  },
  setInsuranceStatus(state, data){
    state.removeInsurance = data
  },

  reset: (state) => {
    const initial = initiateState()
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key]
    })
  },
}
const actions = {
  fetchAllInsurances: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })

      const response = await axios.get("insurance-payors", { params })

      commit("setInsurances", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  createInsurance: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setAddInsurance", false)
      await axios.post("insurance-payors", params )

      commit("setAddInsurance", true)
    } catch (error) {
      commit("setAddInsurance", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  getInsuranceById: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      let insurance = params.insurance_id
      const response = await axios.get(`insurance/${insurance}`, { params })

      commit("setInsuranceDetails", response.data)
    } catch (error) {
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  updateInsurance: async ({ commit, dispatch }, {insuranceData,insurance}) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setUpdateInsurance", false)
      await axios.patch(`insurance/${insurance}`, insuranceData )

      commit("setUpdateInsurance", true)
    } catch (error) {
      commit("setUpdateInsurance", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  exportInsurances: async ({ commit, dispatch }, params) => {
    try {
      await dispatch("errors/clear", [], { root: true })
      commit("setInsuranceReport", false)
      let response = await axios.get("insurances/download-as-csv", {params} )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "Insurances.csv")
      document.body.appendChild(link)
      link.click()
      commit("setInsuranceReport", true)
    } catch (error) {
      commit("setInsuranceReport", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
  removeInsuranceById: async ({ commit, dispatch }, params) => {
    try {
      let insurance  = params.insurance_id
      delete params.insurance_id
      await dispatch("errors/clear", [], { root: true })
      commit("setInsuranceStatus", false)
      await axios.delete(`/insurance-payors/${insurance}`)

      commit("setInsuranceStatus", true)
    } catch (error) {
      commit("setInsuranceStatus", false)
      if (error.response) {
        await dispatch("errors/errorResponse", error.response, {
          root: true,
        })
      }
    }
  },
}
export default {
  namespaced: true,
  mutations,
  getters,
  state,
  actions,
}
