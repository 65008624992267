import AuthMiddleware from "@/middleware/auth";

const AllHospitals = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/views/hospitals/AllHospitals.vue"
  );
const EditHospital = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/EditHospital.vue"
  );
const ActiveHospitals = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/views/hospitals/ActiveHospitals.vue"
  );
const InActiveHospitals = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/views/hospitals/InActiveHospitals.vue"
  );
const EditAccountManager = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/accountsManagers/EditAccountManager.vue"
  );
const EditLabAccountManager = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/labAccountManagers/EditAccountManager.vue"
  );
const EditHospitalAdmin = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/admins/EditHospitalAdmins.vue"
  );
const EditHospitalMarketers = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/marketers/EditHospitalMarketers.vue"
  );
const AddBranch = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/branches/AddBranch.vue"
  );
const EditHospitalPhysician = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/physicians/HospitalPhysicianEdit.vue"
  );
const EditBranch = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/branches/EditBranch.vue"
  );
const HospitalDetails = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/views/hospitals/HospitalDetails.vue"
  );

const NewHospital = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/views/hospitals/NewHospital.vue"
  );
const HospitalPrimaryInformation = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/HospitalPrimaryInformation"
  );
const BranchesList = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/branches/BranchesList"
  );
const HospitalPhysicians = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/physicians/HospitalPhysicians"
  );
const HospitalAccountManagers = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/accountsManagers/HospitalAccountManagers"
  );
const LabAccountManagers = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/labAccountManagers/HospitalAccountManagers"
  );
const HospitalAdmins = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/admins/HospitalAdmins"
  );
const HospitalSettings = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/settings/HospitalSettings"
  );
const HospitalMarketers = () =>
  import(
    /* webpackChunkName: "hospital" */ "@/components/hospitals/marketers/HospitalMarketers"
  );

const HospitalRouter = [
  {
    path: "/ordering-facilities",
    name: "AllHospitals",
    component: AllHospitals,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ORDERING FACILITIES",
    },
  },

  {
    path: "/ordering-facility/:hospital_id/edit",
    name: "EditHospital",
    component: EditHospital,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EDIT ORDERING FACILITY",
    },
  },
  {
    path: "/physician/:physician_id/edit",
    name: "EditHospitalPhysician",
    component: EditHospitalPhysician,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EDIT PHYSICIAN",
    },
  },
  {
    path: "/ordering-facilities/active",
    name: "ActiveHospitals",
    beforeEnter: AuthMiddleware.user,
    component: ActiveHospitals,
    meta: {
      title: " ORDERING FACILITIES",
    },
  },
  {
    path: "/ordering-facilities/inactive",
    name: "InActiveHospitals",
    beforeEnter: AuthMiddleware.user,
    component: InActiveHospitals,
    meta: {
      title: "ORDERING FACILITIES",
    },
  },
  {
    path: "/ordering-facility",
    name: "NewHospital",
    component: NewHospital,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ORDERING FACILITY",
    },
  },
  {
    path: "/ordering-facility/:hospital_id",
    name: "HospitalDetails",
    component: HospitalDetails,
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ORDERING FACILITY",
    },
    children: [
      {
        path: "",
        name: "Hospital-Primary-Information",
        component: HospitalPrimaryInformation,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/branches",
        name: "Branches-List",
        component: BranchesList,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/physicians",
        name: "HospitalPhysicians",
        component: HospitalPhysicians,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/account-managers",
        name: "HospitalAccountManagers",
        component: HospitalAccountManagers,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/lab-managers",
        name: "LabAccountManagers",
        component: LabAccountManagers,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/admins",
        name: "HospitalAdmins",
        component: HospitalAdmins,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/marketers",
        name: "HospitalMarketers",
        component: HospitalMarketers,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/settings",
        name: "Hospital-Settings",
        component: HospitalSettings,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/add-branch",
        name: "Add-Branch",
        component: AddBranch,
        beforeEnter: AuthMiddleware.user,
      },
      {
        path: "/ordering-facility/:hospital_id/edit-account-manager/:user_id",
        name: "EditAccountManager",
        component: EditAccountManager,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "EDIT MANAGER",
        },
      },
      {
        path: "/ordering-facility/:hospital_id/edit-lab-manager/:user_id",
        name: "EditLabAccountManager",
        component: EditLabAccountManager,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "EDIT MANAGER",
        },
      },
      {
        path: "/ordering-facility/:hospital_id/edit-ordering-facility-admin/:user_id",
        name: "EditHospitalAdmin",
        component: EditHospitalAdmin,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "EDIT ADMIN",
        },
      },
      {
        path: "/ordering-facility/:hospital_id/edit-ordering-facility-marketer/:user_id",
        name: "EditHospitalMarketers",
        component: EditHospitalMarketers,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "EDIT MARKETER",
        },
      },
      {
        path: "/ordering-facility/:hospital_id/branch/:location_id",
        name: "Edit-Branch",
        component: EditBranch,
        beforeEnter: AuthMiddleware.user,
        meta: {
          title: "EDIT BRANCH",
        },
      },
    ],
  },
];

export { HospitalRouter };
